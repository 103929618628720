<template>
  <SubNav class="subnav-position" :selected="'comments'" :id="id" />
  <main>
    <div v-if="offeringComment" class="subnav-detail">
      <div class="float-end">
        <router-link :to="{ name: 'OfferingComments', params: { id: id } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <div v-if="!user.emailVerified" class="form-outline mb-4">
        <h6>You must validate your email address to comment.</h6>
        <p>
          When you signed up, you received a validation email with a link to
          validate your email. If you don't have that email, you can request a
          new one on your account page,
          <router-link :to="{ name: 'Account' }">here</router-link>.
        </p>
        <hr class="featurette-divider" />
      </div>
      <div v-else>
        <h6>Question / Comment</h6>
      </div>
      <div>
        <div class="d-flex justify-content-between align-items-baseline">
          <div class="name">
            {{ offeringComment.commenterDisplayName }}
          </div>
          <div class="legal" v-if="offeringComment.isHyping">
            *issuer founder, employee, or otherwise compensated to promote this
            offering*
          </div>
          <div class="created-at">
            {{ offeringComment.date }}
          </div>
        </div>
        <div class="subject">
          {{ offeringComment.subject }}
        </div>
        <div class="comment">
          {{ offeringComment.comment }}
        </div>
        <span
          v-if="!offeringComment.requestDelete"
          class="btn btn-link text-danger float-end"
          @click="handleRequest(offeringComment.id)"
        >
          request delete</span
        >
        <div v-if="offeringComment.requestDelete" class="name">
          *Delete Requested*
        </div>
      </div>

      <div class="pt-4" v-if="formattedReplies">
        <div class="error">{{ getReplyError }}</div>
        <h6>Replies</h6>
        <div class="reply-window">
          <div class="error">{{ formError }}</div>
          <div v-for="reply in formattedReplies" :key="reply.id">
            <div class="single-reply">
              <div class="float-end">
                <span
                  v-if="!reply.requestDelete"
                  class="btn btn-link text-danger"
                  @click="handleReplyRequest(reply.id)"
                >
                  request delete</span
                >
                <div v-if="reply.requestDelete">
                  delete requested
                </div>
              </div>
              <div class="created-at">
                {{ reply.createdAt }}
              </div>
              <div class="d-flex justify-content-start align-items-baseline">
                <div class="name">
                  {{ reply.commenterDisplayName }}
                </div>
                <div class="legal" v-if="reply.isHyping">
                  *issuer founder, employee, or otherwise compensated to promote
                  this offering*
                </div>
              </div>
              <div class="message">
                {{ reply.comment }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!formattedReplies && !isLoading && isCommenter">
        <div class="col">
          <span class="btn btn-link text-danger" @click="handleDelete">
            Delete Comment
          </span>
        </div>
      </div>
      <div v-if="user.emailVerified">
        <form v-if="!isLoading" @submit.prevent="handleReply">
          <div class="form-outline mb-4">
            <label>Reply</label>
            <textarea v-model="comment" required class="form-control long-text">
            </textarea>
          </div>
          <div v-if="!isPending" class="row text-center">
            <div class="col">
              <button class="btn btn-primary">Reply</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
  <Footer v-if="replies" />
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import getDocument from "@/composables/getDocument";
import getCollection from "@/composables/getCollection";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";
import getUser from "@/composables/getUser.js";
import { timestamp } from "@/firebase/config";
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import { formatDistanceToNow } from "date-fns";
import { useRouter } from "vue-router";
import Footer from "@/components/navigation/Footer.vue";

export default {
  props: ["id", "commentId"],
  components: { SubNav, Footer },
  setup(props) {
    const isPending = ref(false);
    const formError = ref(null);
    const comment = ref("");
    const { user } = getUser();
    const router = useRouter();
    const { document: offeringComment } = getDocument(
      "offeringComments",
      props.commentId
    );
    const {
      error: useDocError,
      updateDoc: updateComment,
      deleteDoc: deleteComment
    } = useDocument("offeringComments", props.commentId);
    const {
      isLoading,
      error: getReplyError,
      documents: replies
    } = getCollection(`offeringComments/${props.commentId}/replies`, null, [
      "createdAt",
      "asc"
    ]);

    // mounted
    onMounted(async () => {
      console.log("Component is mounted!");
      await updateComment({
        updatedBy: user.value.uid
      });
    });

    const { error: createReplyError, addDoc: addReply } = useCollection(
      `offeringComments/${props.commentId}/replies`
    );

    const isCommenter = computed(() => {
      return offeringComment.value.commenterId == user.value.uid;
    });

    const formattedReplies = computed(() => {
      if (replies.value) {
        if (replies.value.length > 0) {
          return replies.value.map(reply => {
            let time = formatDistanceToNow(reply.createdAt.toDate());
            return { ...reply, createdAt: time };
          });
        } else {
          return null;
        }
      }
    });

    const handleReply = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        await addReply({
          comment: comment.value,
          commenterId: user.value.uid,
          commenterDisplayName: user.value.displayName,
          isHyping: true,
          createdAt: timestamp()
        });
        isPending.value = false;
        if (createReplyError.value) {
          formError.value = createReplyError.value;
        } else {
          comment.value = "";
          console.log("ReplyCount: ", offeringComment.value.replyCount);
          await updateComment({
            updatedAt: timestamp(),
            updatedBy: user.value.uid,
            replyCount: offeringComment.value.replyCount + 1
          });
          if (useDocError.value) {
            formError.value = useDocError.value;
          }
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    const handleDelete = async () => {
      formError.value = null;
      if (replies.value.length == 0) {
        isPending.value = true;
        await deleteComment();
        isPending.value = false;
        if (useDocError.value) {
          formError.value = useDocError.value;
        } else {
          router.push({ name: "OfferingComments", params: { id: props.id } });
        }
      } else {
        formError.value = "Cannot delete a comment with replies.";
      }
    };

    const handleRequest = async commentId => {
      isPending.value = true;
      const { error: useDocError, updateDoc: updateComment } = useDocument(
        "offeringComments",
        commentId
      );
      formError.value = null;
      await updateComment({
        requestDelete: true,
        requestDeleteBy: user.value.uid,
        requestedAt: timestamp()
      });
      isPending.value = false;
      if (useDocError.value) {
        formError.value = useDocError.value;
      } else {
        router.push({ name: "OfferingComments", params: { id: props.id } });
      }
    };

    const handleReplyRequest = async replyId => {
      const { error: useDocError, updateDoc: updateReply } = useDocument(
        `offeringComments/${props.commentId}/replies`,
        replyId
      );
      formError.value = null;
      await updateComment({
        replyRequestDelete: true,
        updatedAt: timestamp(),
        updatedBy: user.value.uid
      });
      if (useDocError.value) {
        isPending.value = false;
        formError.value = useDocError.value;
      } else {
        await updateReply({
          requestDelete: true,
          requestDeleteBy: user.value.uid,
          requestedAt: timestamp()
        });
        if (useDocError.value) {
          formError.value = useDocError.value;
        }
      }
    };

    return {
      comment,
      getReplyError,
      createReplyError,
      formError,
      isPending,
      isLoading,
      isCommenter,
      offeringComment,
      handleReply,
      handleReplyRequest,
      handleDelete,
      handleRequest,
      formattedReplies,
      user,
      replies
    };
  }
};
</script>

<style scoped>
form {
  max-width: 100%;
  margin: 0 auto;
  padding: 30px;
  border-radius: 8px;
  box-shadow: none;
  border: transparent;
  background: white;
}
</style>